import { BACKEND_URL } from '../utils/constant';
import axios from 'axios';

export let token = null

export const setToken = (newToken) => {
    token = newToken
}

export let user = {}

export const setUser = (newUser) => {
    user = newUser
}

const login = async (credentials) => {
    const response = await axios.post(`${BACKEND_URL}/auth/api/admin/login`, credentials)
    return response
}

const serviceToAdmin = async (credentials) => {
    const config = {
        headers: { authorization: `Bearer ${token}`, 'Access-Control-Allow-Origin': '*' }
    }
    const response = await axios.post(`${BACKEND_URL}/admin/serviceToAdminBalanceTransfer`, credentials ,config)
    return response
}

const adminToAdmin = async (credentials) => {
    const config = {
        headers: { authorization: `Bearer ${token}`, 'Access-Control-Allow-Origin': '*' }
    }
    const response = await axios.post(`${BACKEND_URL}/admin/adminToAdminBalanceTransfer`, credentials ,config)
    return response
}

const changePassword = async (credentials) => {
    const config = {
        headers: { authorization: `Bearer ${token}`, 'Access-Control-Allow-Origin': '*' }
    }
    const response = await axios.post(`${BACKEND_URL}/admin/adminPasswordChange`, credentials, config)
    return response
}

const changeAdminPassword = async (credentials) => {
    const config = {
        headers: { authorization: `Bearer ${token}`, 'Access-Control-Allow-Origin': '*' }
    }
    const response = await axios.post(`${BACKEND_URL}/admin/changeOtherAdminPassword`, credentials, config)
    return response
}

const changePassengerDriver = async (credentials) => {
    const config = {
        headers: { authorization: `Bearer ${token}`, 'Access-Control-Allow-Origin': '*' }
    }
    const response = await axios.post(`${BACKEND_URL}/admin/changePassengerDriverPassword`, credentials, config)
    return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    login,
    serviceToAdmin,
    adminToAdmin,
    changePassword,
    changeAdminPassword,
    changePassengerDriver
}